import UnderConstruction from "./components/Underconstruction";
import "./index.css";

function App() {
  return (
    <>
      <UnderConstruction />
    </>
  );
}

export default App;
